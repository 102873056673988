import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import { useCardNotReceivedModal } from '../CardNotReceivedModal/useCardNotReceivedModal'
import { ActivateTokamakCardModalContent } from './ActivateTokamakCardModalContent'

interface Props {
  limitActivationDate: string
}
export const useActivateTokamakCardModal = ({ limitActivationDate }: Props) => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])
  const { showModal: showCardNotReceivedModal } = useCardNotReceivedModal({
    onBack: () => showActivateTokamakCardModal(),
  })

  const { showModal: showActivateTokamakCardModal, hideModal } = useCtaModal({
    width: 'large',
    content: (
      <ActivateTokamakCardModalContent
        limitActivationDate={limitActivationDate}
      />
    ),
    overlayTransitionType: 'transition-none',
    primaryButtonText: t('common:understood'),
    secondaryButtonText: t('activateTokamakCardModal.cardNotReceivedButton'),
    onSecondaryClick: showCardNotReceivedModal,
    i18n,
  })

  return { showModal: showActivateTokamakCardModal, hideModal }
}
