import { Image } from 'modules/common/components/Image/Image'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { EnumVerticalStepStatus } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep.theme'
import { VerticalProgressStepper } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStepper'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  limitActivationDate: string
}

export const ActivateTokamakCardModalContent = ({
  limitActivationDate,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const stepperSteps = t('activateTokamakCardModal.stepperSteps', {
    returnObjects: true,
  })
  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <Image
        alt="card-order"
        src="/assets/img/card-order.png"
        width="375"
        height="240"
      />
      <h2 className="text-primary-500">
        {t('activateTokamakCardModal.title', {
          limitActivationDate: new Date(limitActivationDate),
        })}
      </h2>
      <div className="flex flex-col gap-4 text-left">
        <p className="font-bold">
          {t('activateTokamakCardModal.stepperTitle')}
        </p>
        <VerticalProgressStepper>
          {stepperSteps.map((label, index) => (
            <VerticalProgressStep
              key={label}
              icons={{ [EnumVerticalStepStatus.ONGOING]: index + 1 }}
              status={EnumVerticalStepStatus.ONGOING}
              title={label}
            />
          ))}
        </VerticalProgressStepper>
      </div>
    </div>
  )
}
